import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import MainList from './Views/MainList';
import Categories from './Views/Categories';
import Header from './Components/Header/Header';

import DetailedList from './Views/DetailedList';
import { LanguageProvider } from './Context/LanguageContext';
import SubCategories from './Views/SubCategories';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: 'categories',
    element: <Categories />
  },
  {
    path: 'categories/:id',
    element: <Categories />
  },
  {
    path: 'mainlist/:id',
    element: <MainList type={'foods'}/>
  },
  {
    path: 'subcategories/:id',
    element: <SubCategories type={'foods'}/>
  },
  {
    path: 'foods/',
    element: <MainList type={'foods'}/>
  },
  {
    path: 'drinks',
    element: <MainList type={'drinks'}/>
  },
  {
    path: 'foods/:id',
    element: <DetailedList type={'foods'}/>
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <RouterProvider router={router}>

      </RouterProvider>
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
