import { Avatar } from '@mui/material';
import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { LanguageContext } from '../../Context/LanguageContext';

import './LanguageCard.css';

const LanguageCard = ({title, flag, change}) => {
    const { changeLanguage } = useContext(LanguageContext)

    const onselect = () => {
        changeLanguage(flag)
    }

    return (
        <Link to={'/categories'}>
            <div
                className={`language-card`}
                onClick={onselect}
            > 
                <Avatar
                    alt={title}
                    src={`https://flagcdn.com/${flag}.svg`}
                    sx={{ width: 54, height: 54 }}
                />
            </div>
        </Link>
    )
}

export default LanguageCard