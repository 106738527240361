import './App.css';


import Header from './Components/Header/Header';

import LanguageCard from './Components/LanguageCard/LanguageCard';

const languages = [
  {
    title: 'Croatian',
    image: 'hr',
    key: 'hr'
  },
  {
      title: 'English',
      image: 'us',
      key: 'us'
  },
  {
      title: 'French',
      image: 'fr',
      key: 'fr'
  },
  {
      title: 'German',
      image: 'de',
      key: 'de'
  },
  {
      title: 'Italian',
      image: 'it',
      key: 'it'
  },
  {
      title: 'Polish',
      image: 'pl',
      key: 'pl'
}]

function App() {

  return (
      <div className="App">
        <Header />
        <div className='flex justify-center items-center h-[calc(100vh-50px)]'>
          <div className=''>
            {
              languages.map((language) => (
                <LanguageCard 
                  key={language.key} 
                  flag={language.key}
                  title={language.title}
                  />
              ))
            }
          </div>
        </div>
      </div>
  );
}

export default App;
