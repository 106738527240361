import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom';
import Card from '../Components/Card/Card';
import Header from '../Components/Header/Header';
import { LanguageContext } from '../Context/LanguageContext';

import Data from '../Data/Data.json'
const SubCategories = () => {
    const params = useParams()
    const {language } = useContext(LanguageContext)

    const duplicates = Data.subcategory.filter((x) => x.category_id ===  params.id)
    return (
        <>
            <Header />
            <div className='flex justify-center pb-[20px] pt-[55px]'>
                    <div className='w-[80%] mt-[20px]'>
                        {
                            duplicates.map((item, index) => {
                                const name =  'category_' + language;
                                return(
                                    <Link key={index} to={`/mainlist/${item.id}`}>
                                        <Card 
                                            title={item[name]}
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
        </>
    )
}

export default SubCategories