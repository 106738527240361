import React, { useContext, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { Avatar, Button } from '@mui/material';

import LangugageDialog from '../LanguageDialog/LanguageDialog';
import { LanguageContext } from '../../Context/LanguageContext';
import Logo from '../../Images/pumparela_org.png'
import { useNavigate, useLocation} from "react-router-dom";
const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {language, changeLanguage } = useContext(LanguageContext)

    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState('us')

    const [isHomePage] = useState(location.pathname === '/' ? true : false)

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = (value) => {
        changeLanguage(value)
        setOpen(false);
    };


    return (
        <>
            <div className={`flex items-center ${isHomePage ? 'justify-center' : 'justify-between'} fixed w-full h-[50px] bg-[#3d4c32] text-white pl-[10px] pr-[10px]`}>
                {
                    !isHomePage && (
                        <div className='flex justify-between w-[80px]'>
                            <button onClick={() => navigate(-1)}>
                                <ArrowBackIcon sx={{ fontSize: 30 }}/>
                            </button>
                            <button onClick={() => navigate('/')}>
                                <HomeIcon sx={{ fontSize: 30 }}/>
                            </button>
                        </div>
                    )
                }
                <div >
                    <img className='w-[90px]' src={Logo} />
                </div>
                {
                    !isHomePage && (
                        <Button className='width-[30p]' onClick={handleClickOpen}>
                            <Avatar
                                sx={{width: 30, height: 30}}
                                src={`https://flagcdn.com/w160/${language}.png`}
                            />
                        </Button>
                    )
                }
            </div>
            <LangugageDialog 
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose} 
            />
        </>
    )
}

export default Header