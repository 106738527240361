import React from 'react'
import Header from '../Components/Header/Header';

import Data from '../Data/Data.json'

const DetailedList = () => {
    return (
        <>
            <Header />
            <div className='flex justify-center'>
                <div className='w-[80%] mt-[20px]'>
                </div>
            </div>
        </>
    )
}

export default DetailedList