import React, {createContext, useEffect, useState} from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage !== null ? storedLanguage : 'us';
    });

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage)
    };

    useEffect(() => {
        localStorage.setItem('language', language)
    }, [language])
    return (
        <LanguageContext.Provider value={{language, changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
};
