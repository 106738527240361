import { Avatar, Dialog, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React, { useCallback } from 'react'

const languages = [
    {
        title: 'Croatian',
        image: 'hr',
        key: 'hr'
    },
    {
        title: 'English',
        image: 'us',
        key: 'us'
    },
    {
        title: 'French',
        image: 'fr',
        key: 'fr'
    },
    {
        title: 'German',
        image: 'de',
        key: 'de'
    },
    {
        title: 'Italian',
        image: 'it',
        key: 'it'
    },
    {
        title: 'Polish',
        image: 'pl',
        key: 'pl'
}]

const LangugageDialog = ({selectedValue, open, onClose}) => {

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };


    return (
        <div>
            <Dialog
                maxWidth='lg'
                open={open}
                onClose={handleClose}
            >
                <List>
                    {
                        languages.map((language) => (
                            <ListItem key={language.title} >
                                <ListItemButton 
                                    onClick={() => handleListItemClick(language.key)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={language.title}
                                            src={`https://flagcdn.com/w160/${language.image}.png`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={language.title}/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </Dialog>
        </div>
    )
}

export default LangugageDialog