import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Card from '../Components/Card/Card'
import Header from '../Components/Header/Header'
import { LanguageContext } from '../Context/LanguageContext'

import Data from '../Data/Data.json'

const MainList = () => {
    const params = useParams()
    const [ data ] = useState(Data[params.id] || null)
    const { language } = useContext(LanguageContext)

    return (
        <>
            <Header />
            <div className='flex justify-center pb-[20px] pt-[55px]'>
                <div className='w-[80%] mt-[20px]'>
                    {
                        data !== null && (
                            data.map((item,index) => {
                                const name =  'name_' + language;
                                return(
                                    <Card
                                        key={index}
                                        classes={'text-[13px]'}
                                        title={item[name]}
                                        price={item.price}
                                        volume={item.volume}
                                    />
                                )
                            })
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default MainList