
import React, { useContext, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { LanguageContext } from '../Context/LanguageContext';

import Card from '../Components/Card/Card';
import Header from '../Components/Header/Header';

import Data from '../Data/Data.json';

const Categories = () => {
    const params = useParams()
    const [data] = useState(Data)
    const {language } = useContext(LanguageContext)
    const [showCategories, setShowCategories] = useState(false)
    const [homeName, setHomeName] = useState({
        foods: 'category_' + language,
        drinks: 'category_' + language
    })
    useEffect(() => {
        if(params.id !== undefined){
            setShowCategories(true)
        } else {
            setShowCategories(false)
        }
        
    }, [params])

    useEffect(() => {
        setHomeName({
            foods: 'category_' + language,
            drinks: 'category_' + language
        })
    }, [language])

    const findCategoriesWithSubcatogory = (id) => {
        let count = 0;
        data.subcategory.map((item) => {
            if(item.category_id === id) {
                count ++;
            }
        })

        return count > 1
    }

    return (

        <>
            <Header />
            <div className='flex justify-center pb-[20px] pt-[55px]'>
                <div className={`w-[80%] mt-[20px] `}>
                    {
                        !showCategories ? (
                            <>
                                <Link to={`/categories/1`}>
                                    <Card title={data.home[0][homeName.foods]}/>
                                </Link>
                                <Link to={'/subcategories/8'}>
                                    <Card title={data.home[1][homeName.foods]}/>
                                </Link>
                            </>
                        ) : (
                            <>
                                {
                                    data.categories !== null && (
                                        data.categories.map((item, index) => {
                                            const name =  'category_' + language;
                                            const subs = findCategoriesWithSubcatogory(item.id);
                                            const mainId = data.subcategory.find(obj => obj.category_id === item.id);
                                            return(
                                                !subs ? (
                                                    <Link key={index} to={`/mainlist/${mainId.id}`}>
                                                        <Card title={item[name]}/>
                                                    </Link>
                                                    
                                                ) : (
                                                    <Link key={index} to={`/subcategories/${item.id}`}>
                                                        <Card title={item[name]}/>
                                                    </Link>
                                                )
                                            )
                                        }
                                    )
                                )}
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Categories