import { Box } from '@mui/material'
import React from 'react'

import './Card.css'

const Card = ({title, price, classes, volume, change}) => {
    return (
        <div 
        className={`card ${classes}`}
        onClick={change}
        >
            <div className='flex justify-between'>
                <div>
                    {title}
                </div>
                <div className='font-semibold ml-[15px] text-right'>
                    {price}
                </div>
            </div>
            <div>
                {
                    volume && (
                        <div className='text-[12px] text-[#828282]'>
                            {volume}
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default Card